






































import { Vue, Component, VModel, Ref } from 'vue-property-decorator'
import { QSelect } from 'quasar'

@Component
export default class BaseInputSelect extends Vue {
  @VModel({
    type: [String, Number, Object, Array],
    required: false,
  }) selection!: unknown

  @Ref() private readonly select!: QSelect

  private clearInput (): void {
    this.select.updateInputValue('')
  }
}
