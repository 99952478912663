





























































import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import { Contact } from '@tada-team/tdproto-ts'
import BaseInputSelect from '@/components/UI/BaseInputSelect.vue'
import BaseAvatar from '@/components/UI/BaseAvatar.vue'
import Fuse from 'fuse.js'
import { getDefaultFuseOptions } from '@/utils'
import { contactsStore } from '@/store'
import { multiSearch } from '@/utils/Common'

@Component({
  components: {
    BaseInputSelect,
    BaseAvatar,
  },
})
export default class ContactSelector extends Vue {
  @VModel({
    required: false,
    type: Contact,
  }) contact!: Contact

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly placeholder!: string

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  }) readonly useInput!: boolean

  private readonly fuseOptions = getDefaultFuseOptions(['displayName'])

  private filterString = ''

  get items () {
    const query = this.filterString.trim()
    return !query.length
      ? this.contacts
      : multiSearch((s: string) => this.fuse.search(s), query, 'jid')
  }

  get contacts () {
    return contactsStore.getters.teamMembers
  }

  private selectedContact () {
    this.$emit('selectedContact')
  }

  private get fuse (): Fuse<Contact> {
    return new Fuse(this.contacts, this.fuseOptions)
  }

  private filterFn (value: string, update: (fn : () => void) => void) {
    update(() => {
      this.filterString = value
    })
  }
}
