var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseInputSelect',{staticClass:"redesign contact-selector",attrs:{"options":_vm.items,"placeholder":_vm.placeholder,"use-input":_vm.useInput},on:{"filter":_vm.filterFn,"input":_vm.selectedContact},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var itemProps = ref.itemProps;
var opt = ref.opt;
var selected = ref.selected;
var toggleOption = ref.toggleOption;
var itemEvents = ref.itemEvents;
return [_c('q-item',_vm._g(_vm._b({staticClass:"flex items-center justify-center redesign contact-selector-item",attrs:{"clickable":""}},'q-item',itemProps,false),itemEvents),[_c('q-item-section',{staticClass:"q-pr-sm",attrs:{"side":""}},[_c('BaseAvatar',{attrs:{"size":"24px","icons":opt.icons}})],1),_c('q-item-section',[_c('q-item-label',{attrs:{"lines":"1"}},[_vm._v(" "+_vm._s(opt.displayName)+" ")])],1)],1)]}},{key:"selected-item",fn:function(ref){
var tabindex = ref.tabindex;
var index = ref.index;
var opt = ref.opt;
return [_c('q-item',{staticClass:"flex items-center justify-center redesign q-pa-none",attrs:{"dense":""}},[_c('q-item-section',{staticClass:"q-pr-sm",attrs:{"side":""}},[_c('BaseAvatar',{attrs:{"size":"24px","icons":opt.icons}})],1),_c('q-item-section',[_c('q-item-label',{attrs:{"lines":"1"}},[_vm._v(" "+_vm._s(opt.displayName)+" ")])],1)],1)]}}]),model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}})}
var staticRenderFns = []

export { render, staticRenderFns }