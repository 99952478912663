import { render, staticRenderFns } from "./BaseInputSelect.vue?vue&type=template&id=8fb7f1c6&scoped=true&"
import script from "./BaseInputSelect.vue?vue&type=script&lang=ts&"
export * from "./BaseInputSelect.vue?vue&type=script&lang=ts&"
import style0 from "./BaseInputSelect.vue?vue&type=style&index=0&id=8fb7f1c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fb7f1c6",
  null
  
)

export default component.exports
import {QSelect,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSelect,QField})
