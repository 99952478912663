

































































import { Vue, Component, VModel } from 'vue-property-decorator'
import { QTable } from 'quasar'
import { contactsStore, chatImportStore } from '@/store'
import { MappedUser } from '@tada-team/tdproto-ts'
import BaseAvatar from '@/components/UI/BaseAvatar.vue'
import BaseInputSelect from '@/components/UI/BaseInputSelect.vue'
import ContactSelector from './ContactSelector.vue'
import { TdToggle } from 'td-ui'

@Component({
  components: {
    TdToggle,
    BaseInputSelect,
    BaseAvatar,
    ContactSelector,
  },
})
export default class MappedUsers extends Vue {
  @VModel({
    required: true,
    type: Array,
  }) mappedUsers!: MappedUser[]

  private columns: QTable['columns'] = [{
    name: 'number',
    field: 'number',
    label: '№',
    align: 'left',
    headerClasses: 'members-header',
    classes: 'q-table--col-auto-width',
  }, {
    name: 'externalUserName',
    field: 'externalUserName',
    label: this.$t('import.step3.users.nik').toString(),
    align: 'left',
    headerClasses: 'members-header',
    classes: 'q-table--col-auto-width',
  }, {
    name: 'externalUserId',
    field: 'externalUserId',
    label: this.$t('import.step3.users.id').toString(),
    align: 'left',
    headerClasses: 'members-header',
    classes: 'q-table--col-auto-width',
  }, {
    name: 'isAdmin',
    field: 'isAdmin',
    label: this.$t('import.step3.users.admin').toString(),
    align: 'left',
    headerClasses: 'members-header',
    classes: 'q-table--col-auto-width',
  }, {
    name: 'contact',
    field: 'contact',
    label: this.$t('import.step3.users.contact').toString(),
    headerClasses: 'members-header',
    align: 'left',
  }]

  private pagination: QTable['pagination'] = {
    rowsPerPage: 0,
  }

  get contacts () {
    return contactsStore.getters.contactList
  }

  private isSuccess (member: MappedUser) {
    return !this.mappedUsers.find(m => m.contact?.jid === member.contact?.jid && member.externalUserId !== m.externalUserId)
  }

  private isShowCheckContact (user: MappedUser) {
    return !!user.contact
  }

  get loading () {
    return chatImportStore.state.mappedUsersLoading
  }

  private getCount (mappedUser: MappedUser) {
    return this.mappedUsers.findIndex(m => mappedUser.externalUserId === m.externalUserId) + 1
  }

  private match () {
    this.$emit('match')
  }
}
