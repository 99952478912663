var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-select',_vm._g(_vm._b({ref:"select",staticClass:"base-input-select td-rounded-8",attrs:{"filled":"","options-sanitize":"","dense":"","borderless":"","dropdown-icon":"img:/assets/i/chevron-down.svg"},on:{"input":function (v) { return _vm.$emit('input', v); },"add":function () { return _vm.clearInput(); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var itemProps = ref.itemProps;
var opt = ref.opt;
var selected = ref.selected;
var toggleOption = ref.toggleOption;
var itemEvents = ref.itemEvents;
var focused = ref.focused;
return [_vm._t("option",null,{"itemProps":itemProps,"opt":opt,"selected":selected,"toggleOption":toggleOption,"itemEvents":itemEvents,"focused":focused})]}},{key:"selected-item",fn:function(ref){
var tabindex = ref.tabindex;
var index = ref.index;
var opt = ref.opt;
var removeAtIndex = ref.removeAtIndex;
return [_vm._t("selected-item",null,{"tabindex":tabindex,"index":index,"opt":opt,"removeAtIndex":removeAtIndex})]}}],null,true),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},'q-select',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }